(function () {
  'use strict';

  /* @ngdoc object
   * @name home.facilities
   * @description
   *
   */
  angular
    .module('neo.home.facilities', [
      'ui.router',
      'neo.home.facilities.newFacility',
      'neo.home.facilities.facility'
    ]);
}());
